<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
import PreviousButton from "@/components/buttons/previous-button";
import SaveButton from "@/components/buttons/save-button";

export default {
	components: {
		MessageModal,
		PreviousButton,
		SaveButton,
	},
	props: {
		tutorInfo: Object,
		serviceOptions: Array,
	},
	data() {
		const id = this.$route.params.id;
        let modeName;
		
		if (this.$route.path.includes('view')) {
			modeName = 'View';
		} else {
			modeName = id === 'add' ? 'Add' : 'Edit';
		}

		return {
			id: id,
			modeName,
			formData: {
				profile_pic: [],
				edu_qualification: [],
				specialised_cert: [],
				service_details: {}
			},
			fields: [
				{ name: "qualification", label: "Qualification", placeholder: "Briefly describe your relevant qualifications (e.g., certifications, degrees)." },
				{ name: "personality", label: "Personality", placeholder: "Share a few words about your teaching personality (e.g., enthusiastic, patient, encouraging)." },
				{ name: "teaching_style", label: "Teaching Style", placeholder: "Describe your teaching approach (e.g., interactive, project-based, individualized)." },
				{ name: "years_of_exp", label: "Years of experience", placeholder: "Enter your total years of experience in this field." },
				{ name: "track_records_of_students", label: "Track records of students", placeholder: "Share any relevant achievements of your students (e.g., improved test scores, positive testimonials)." },
			],
			profilePicName: '',
			eduQualificationPicName: '',
			specialisedCertPicName: '',
			selectedServices: [],
			errors: {},
			dataReady: false,
			saveBtnDisabled: false,
		}
	},
	watch: {
		tutorInfo: {
			handler() {
				this.selectedServices = this.selectedServicesFromTutorInfo;

				if (this.tutorInfo.service_details && this.tutorInfo.service_details.length > 0) {
					this.formData.service_details = {};
					
					this.tutorInfo.service_details.forEach(data => {
						if (!this.formData.service_details[data.service_id]) {
							this.$set(this.formData.service_details, data.service_id, {});
						}
						this.$set(this.formData.service_details[data.service_id], 'qualification', data.qualification);
						this.$set(this.formData.service_details[data.service_id], 'personality', data.personality);
						this.$set(this.formData.service_details[data.service_id], 'teaching_style', data.teaching_style);
						this.$set(this.formData.service_details[data.service_id], 'years_of_exp', data.years_of_exp);
						this.$set(this.formData.service_details[data.service_id], 'track_records_of_students', data.track_records_of_students);
					});
				}
			},
			deep: true
		},
		selectedServices(newServices) {
			newServices.forEach(service => {
				if (!this.formData.service_details[service]) {
					this.$set(this.formData.service_details, service, {});
					this.fields.forEach(field => {
						this.$set(this.formData.service_details[service], field.name, "");
					});
				}
			});
		},
	},
	computed: {
		selectedServicesFromTutorInfo() {
			if (this.tutorInfo && this.tutorInfo.services && this.tutorInfo.services.length > 0) {
				try {
					return JSON.parse(this.tutorInfo.services);
				} catch (error) {
					console.error('Error parsing services:', error);
				}
			}
			return [];
		},
        serviceDetails() {
            this.selectedServices.forEach(serviceId => {
                if (!this.formData.service_details[serviceId]) {
                    this.$set(this.formData.service_details, serviceId, {
                        qualification: '',
                        personality: '',
                        teaching_style: '',
                        years_of_exp: '',
                        student_record: ''
                    });
                }
            });
            return this.formData.service_details;
        },
	},
	mounted() {
		console.log('TITITITIs', this.tutorInfo)
		this.selectedServices = this.selectedServicesFromTutorInfo;

		if (this.tutorInfo.profile_pic) {
			this.formData.profile_pic = this.tutorInfo.profile_pic;
			
			this.formData.profile_pic.forEach((image, index) => {
				this.fetchImageFromURL(image.image, 'profile_pic', index);
			});
		}

		if (this.tutorInfo.edu_qualification) {
			this.formData.edu_qualification = this.tutorInfo.edu_qualification;

			this.formData.edu_qualification.forEach((image, index) => {
				this.fetchImageFromURL(image.image, 'edu_qualification', index);
			});
		}

		if (this.tutorInfo.specialised_cert) {
			this.formData.specialised_cert = this.tutorInfo.specialised_cert;

			this.formData.specialised_cert.forEach(image => {
				this.fetchImageFromURL(image.image, 'specialised_cert');
			});
		}

		this.dataReady = true;
	},
	methods: {
		updateFormField(service, field, event) {
			if (!this.formData.service_details[service]) {
				this.$set(this.formData.service_details, service, {});
			}
			this.$set(this.formData.service_details[service], field, event.target.value);
		},
		goToPrevious() {
			this.$emit('go-to-previous');
		},
		handleProfilePicInput(event) {
			const file = event.target.files[0];
            if (file && ['image/png','image/jpeg'].includes(file.type)) {
				this.profilePicName = file.name;
				const reader = new FileReader();
				reader.onloadend = () => {
					this.formData.profile_pic[0] = {
						base64Image: reader.result,
						name: file.name
					};
				}
				reader.readAsDataURL(file);
            } else {
				alert('Invalid file type. Only PNG and JPG/JPEG are allowed.');
			}
        },
		handleEduQualificationInput(event) {
			const files = event.target.files;

			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const reader = new FileReader();

				if (file && ['image/png','image/jpeg'].includes(file.type)) {
					reader.onloadend = () => {
						this.formData.edu_qualification.push({
							base64Image: reader.result,
							name: file.name
						});
					};
					reader.readAsDataURL(file);
				} else if (file && ['application/pdf'].includes(file.type)) {
					reader.onloadend = () => {
						this.formData.edu_qualification.push({
							base64Image: reader.result,
							name: file.name,
							type: file.type,
							size: file.size
						});
					};
					reader.readAsDataURL(file);
				} else {
					alert('Invalid file type. Only PDF, PNG and JPG/JPEG are allowed.');
				}
			}
        },
		handleSpecialisedCertInput(event) {
			const files = event.target.files;

			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const reader = new FileReader();
				reader.onloadend = () => {
					this.formData.specialised_cert.push({
						base64Image: reader.result,
						name: file.name
					});
				}
				reader.readAsDataURL(file);
			}
        },
		removeImage(imageType, imageIndex) {
			if (imageType === 'edu_qualification') {
				this.tutorInfo.edu_qualification.splice(imageIndex, 1);
			} else if (imageType === 'specialised_cert') {
				this.tutorInfo.specialised_cert.splice(imageIndex, 1);
			}
		},
		fetchImageFromURL(url, type, index) {
			fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onload = () => {
					if (type === 'profile_pic') {
						this.$set(this.formData.profile_pic[index], 'base64', reader.result);
					} else if (type === 'edu_qualification') {
                        this.$set(this.formData.edu_qualification[index], 'base64', reader.result);
                    } else if (type === 'specialised_cert') {
                        this.$set(this.formData.specialised_cert[index], 'base64', reader.result);
                    }
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error('Error fetching image:', error);
            });
		},
		updateServiceDetails(newServices) {
			this.formData.service_details = newServices.map(serviceValue => {
				let existingService = this.formData.service_details.find(detail => detail.service_id === serviceValue);
				if (!existingService) {
					existingService = {
						service_id: serviceValue,
						qualification: '',
						personality: '',
						teaching_style: '',
						years_of_exp: '',
						track_records_of_students: '',
					};
				}
				return existingService;
			});
		},
		getServiceDetail(serviceId, key) {
            let serviceDetail = this.formData.service_details.find(detail => detail.id === serviceId);
            if (!serviceDetail) {
                serviceDetail = { id: serviceId };
                this.formData.service_details.push(serviceDetail);
            }

            if (!serviceDetail[key]) {
                this.$set(serviceDetail, key, '');
            }
            return serviceDetail[key];
        },
		async save() {
			this.saveBtnDisabled = true;
			this.errors = {};
			// var hasError = false;
			let result;

			if (this.modeName === 'Add') {
				this.saveBtnDisabled = false;
				window.alert('Please fill personal info first');
				this.goToFirstTab();
			} else {
				this.formData.status = 1;
				const requestData = this.formData;
				requestData.step = 3;
				
				if (!requestData.profile_pic) {
					requestData.profile_pic = this.tutorInfo.profile_pic;
				}

				// if (requestData.profile_pic.length == 0) {
				// 	hasError = true;
				// 	this.saveBtnDisabled = false;
				// 	this.errors.profile_pic = [];
				// 	this.errors.profile_pic[0] = "Please upload your profile picture";

				// 	document.getElementById("profile_pic_section").scrollIntoView();
				// }
				
				// if (requestData.edu_qualification.length == 0) {
				// 	hasError = true;
				// 	this.saveBtnDisabled = false;
				// 	this.errors.edu_qualification = [];
				// 	this.errors.edu_qualification[0] = "Please upload at least one educational qualifications";

				// 	document.getElementById("edu_qualification_section").scrollIntoView();
				// }

				// if (requestData.specialised_cert.length == 0) {
				// 	hasError = true;
				// 	this.saveBtnDisabled = false;
				// 	this.errors.specialised_cert = [];
				// 	this.errors.specialised_cert[0] = "Please upload at least one specialised certification";

				// 	document.getElementById("specialised_cert_section").scrollIntoView();
				// }

				// if (hasError) {
				// 	return;
				// }

console.log('requesDataaaa', requestData);
				requestData.service_providers = [];
				if (this.tutorInfo.service_providers) {
					requestData.service_providers = JSON.parse(this.tutorInfo.service_providers);
				}

				result = await axios.put(`${process.env.VUE_APP_APIURL}/tutor/${this.id}`, requestData, {
					headers: authHeader()
				});
			}

			if (result) {
				this.saveBtnDisabled = false;
				if (result.data['code'] === 'success') {
					this.saveBtnDisabled = false;
					this.$refs.messageModal.showModal('Your record has been updated successfully');
				} else if (result.data['code'] === 'invalid_field') {
					this.saveBtnDisabled = false;
					this.errors = result.data['errors'];

					if (this.errors.specialised_cert) {
						document.getElementById("specialised_cert_section").scrollIntoView();
					}
				}
			}
		},
		isServiceIncluded(serviceValue) {
            if (!Array.isArray(this.tutorInfo.services)) {
                this.tutorInfo.services = [this.tutorInfo.services];
            }
            return this.tutorInfo.services.includes(serviceValue);
        },
		goToFirstTab() {
			this.$emit('go-to-first-tab');
		},
		async cancel() {
			this.$router.push({ name: "Tutor" });
		}
	}
}
</script>

<template>
	<div>
		<MessageModal ref="messageModal" />
		<div class="card" style="border-radius: 0;">
			<div class="card-body p-4" stlye="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);">
				<div class="row mb-3">
					<div class="col-12">
						<p class="label" id="profile_pic_section">Profile photo</p>
						<div v-if="profilePicName" class="uploaded_image__blocks mb-3">
							<div class="uploaded_doc">
								{{ profilePicName}}
							</div>
						</div>

						<div v-if="formData.profile_pic.length && !profilePicName">
							<div v-for="(pp_image, index) in formData.profile_pic" :key="index">
								<img :src="pp_image.image" id="pp" alt="profile pic">
							</div>
						</div>
						
						<label v-if="modeName !== 'View'" for="profile_pic" class="label upload__btn">
							Upload new image
							<input type="file" id="profile_pic" @change="handleProfilePicInput($event)">
						</label>
						
						<div v-if="errors.profile_pic" role="alert" class="alert alert-danger mt-2">
							{{ errors.profile_pic[0] }}
						</div>
						
						<p v-if="modeName !== 'View'" class="recommend_text" style="margin-bottom: 5px;">Recommended format: PNG or JPEG</p>
						<p v-if="modeName !== 'View'" class="recommend_text">Recommended size: 170 x 170 pixels</p>
					</div>

					<div class="col-12">
						<p class="label" id="edu_qualification_section">Highest educational qualification</p>
						<p class="mb-0">{{ eduQualificationPicName }}</p>
						<div class="my-3" v-if="formData.edu_qualification.length">
							<div class="uploaded_image__blocks mb-3">
								<div class="uploaded_doc" v-for="(eq_image, index) in formData.edu_qualification" :key="index">
									<a :href="eq_image.image" target="_blank">
										{{ eq_image.name ?? eq_image.label }}
									</a>

									<i v-if="modeName !== 'View'" class="ri-close-line" @click="removeImage('edu_qualification', index)"></i>
								</div>
							</div>
						</div>
						
						<label v-if="modeName !== 'View'" for="edu_qualification" class="label upload__btn">
							Choose file
							<input multiple type="file" id="edu_qualification" @change="handleEduQualificationInput($event)">
						</label>

						<div v-if="errors.edu_qualification" role="alert" class="alert alert-danger mt-2">
							{{ errors.edu_qualification[0] }}
						</div>

						<p v-if="modeName !== 'View'" class="recommend_text">Recommended format: PDF or JPEG</p>
					</div>

					<div class="col-12">
						<p class="label" id="specialised_cert_section">Specialised certification</p>
						<p class="mb-2">{{ specialisedCertPicName }}</p>
						<div class="mt-3" v-if="formData.specialised_cert.length">
							<div class="uploaded_image__blocks mb-3">
								<div class="uploaded_doc" v-for="(sc_image, index) in formData.specialised_cert" :key="index">
									<a :href="sc_image.image" target="_blank">
										{{ sc_image.name ?? sc_image.label }}
									</a>

									<i v-if="modeName !== 'View'" class="ri-close-line" @click="removeImage('specialised_cert', index)"></i>
								</div>
							</div>
						</div>

						<label v-if="modeName !== 'View'" for="specialised_cert" class="label upload__btn">
							Choose file
							<input multiple type="file" id="specialised_cert" @change="handleSpecialisedCertInput($event)">
						</label>
						
						<div v-if="errors.specialised_cert" role="alert" class="alert alert-danger mt-2">
							{{ errors.specialised_cert[0] }}
						</div>

						<p v-if="modeName !== 'View'" class="recommend_text">Recommended format: PDF or JPEG</p>
					</div>
				</div>

				<div v-if="dataReady">
					<div v-for="service in serviceOptions" :key="service.value">
						<div class="service__fields" v-if="selectedServices.includes(service.value)">
							<p class="service__title">{{ service.name }}</p>
							<div class="row">
								<div class="col-12 mb-4" v-for="field in fields" :key="field.name">
								<label :for="field.name + '-' + service.value" class="label">{{ field.label }}</label><br>
								<textarea
									v-model="formData.service_details[service.value][field.name]"
									@input="updateFormField(service.value, field.name, $event)"
									class="form-control"
									:id="field.name + '-' + service.value"
									cols="30"
									rows="8"
									:placeholder="field.placeholder"
									:disabled="modeName === 'View'"
								></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="modeName !== 'View'" class="divider"></div>

				<div v-if="modeName !== 'View'" class="row mt-2">
					<div class="col-12" style="text-align: right;">
						<PreviousButton @click="goToPrevious" />
						<SaveButton @click="save" :disabled="saveBtnDisabled" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.alert {
	width: fit-content;
}
input[type=file] {
	display: none;
}

label {
	cursor:pointer;
}

#pp, #eq, #sc {
	width: 80px;
	height: 80px;
	border-radius: 4px;
	margin-bottom: 20px;
}

.upload__btn {
	width: fit-content;
	background-color: #fff;
	border: 1px solid rgba(45, 55, 72, 0.5);
	border-radius: 6px;
	padding: 11px 16px;
	margin-bottom: 8px;
	font-family: Nunito;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: #2D3748;
}

.uploaded_image__blocks {
	display: flex;
	gap: 12px;
}

.uploaded_doc {
	border: 1px solid #38B6FF;
	border-radius: 4px;
	background-color: rgba(56, 182, 255, 0.1);
	padding: 12px;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-around;

	i {
		cursor: pointer;
	}
}

.recommend_text {
	color: rgba(80, 93, 105, 0.5);
	font-family: Nunito;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 32px;
}

.service__title {
	color: #505D69;
	font-family: 'Inter';
	font-weight: 700;
	font-size: 18px;
	line-height: 27px;
	border-left: 5px solid #38B6FF;
	padding-left: 8px;
	margin-bottom: 24px;
}
</style>